import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  // 设置全局访问的state对象
  state: {
    count: 25,
    todos: [
      { id: 1, text: '...', done: true },
      { id: 2, text: '...', done: false },
      { id: 3, text: '...', done: true },
      { id: 4, text: '...', done: true },
      { id: 5, text: '...', done: true },
      { id: 6, text: '...', done: true },
      { id: 7, text: '...', done: true },
      { id: 8, text: '...', done: true },
    ],
    tagList:[],
    coordinatorList:[],
    relatedContractList:[],
    contentHtml:'',
    contractObject:{},
    selectuser:[],
    projectApprovalFile:null,
    // 模板表格数据
    tableTemList:[],
    oppsiteItem:{},//台账对象
    oppsiteItemDetail:{}
  },
  getters: {
    tdoneTodos: state => {
      // 获取列表中done属性为true的
      return state.todos.filter(todo => todo.done)
    },
    getTabList:state =>{
      if(tagList[0]){
        return state.tagList.filter(tagList => tagList)

      }
    }
  },
  // 修改状态
  mutations: {
    increment(state) {
      state.count++;
    },
    changTagsList(state,list){
      state.tagList = list
      
    },
    getCoordinatorList(state,list){
      state.coordinatorList = list
    },
    getRelatedContractList(state,list){
      state.relatedContractList = list
    },
    getContentHtml(state,content){
      state.contentHtml = content
    },
    getContractObject(state,content){
      state.contractObject = content
    },
    getSelectUserList(state,content){
      state.selectuser = content
    },
    getProjectApprovalFile(state,content){
      state.projectApprovalFile = content
    },
    getTableTemList(state,list){
      state.tableTemList = list
    },
    getOppsiteItem(state,object){
      state.oppsiteItem = object
    },
    getOppsiteItemDetail(state,content){
      state.oppsiteItemDetail = content
    }
  },
  actions: {
    increment (context) {
      context.commit('increment')
    }
  },
  modules: {},
});
