var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "box",
    staticStyle: {
      "overflow-x": "auto",
      "width": "98%"
    },
    style: {
      height: _vm.height + 'px'
    }
  }, [_c('div', {
    staticStyle: {
      "width": "30px",
      "margin": "20% auto"
    }
  }, [_c('van-loading', {
    attrs: {
      "color": "#1989fa",
      "type": "spinner"
    }
  })], 1), _c('div', {
    staticClass: "nextStepBox",
    on: {
      "click": _vm.nextStep
    }
  }, [_c('div', {
    staticClass: "nextStep"
  }, [_vm._v("下一步")])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };